import { useLocation } from "react-router-dom";

import { FEED_TYPES } from "constants/feedTypes";
import { IntelligenceReportsSingleOpportunityPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleOpportunity";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetTableData, useGetTableFilters } from "reactQuery/hooks/useTableData";
import { IndicatorFeedsSingleOpportunityPathname } from "routes/IndicatorFeeds";
import {
    AllFeedbackSingleFeedbackPathname,
    AllFeedbackSingleOpportunityPathname,
    AllIndicatorsSingleOpportunityPathname,
    AssignedInsightsSingleAssigneePathname,
    AssignedInsightsSingleOpportunityPathname,
    AllInsightsSingleOpportunityPathname,
    AllOwnersSingleOpportunityPathname,
    MyInsightsSingleOpportunityPathname,
    PriorityInsightsSingleOpportunityPathname,
    AllOwnersSingleOwnerPathname,
    AllIndicatorsSingleIndicatorPathname,
} from "routes/IntelligenceFeeds";

import emptyData from "./emptyData";

export const useTableDependencies = ({
    contentId,
    feedType,
    filtersEnabled,
    teamId,
}) => {
    const getWhoamiQuery = useGetWhoami();
    const { search } = useLocation();
    const excludeQueryParams = [
        "feedback",
        "indicator_id",
        "opportunity_id",
        "owner_id",
        "user_id",
    ];
    const singleAssigneePathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AssignedInsightsSingleAssigneePathname,
        pathQueryParam: "user_id",
        setQueryParam: true,
    };
    const singleFeedbackPathParams = {
        dataKey: "feedback_name",
        excludeQueryParams,
        path: AllFeedbackSingleFeedbackPathname,
        pathQueryParam: "feedback",
        setQueryParam: true,
    };
    const singleIndicatorPathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AllIndicatorsSingleIndicatorPathname,
        pathQueryParam: "indicator_id",
        setQueryParam: true,
    };
    const singleOwnerPathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AllOwnersSingleOwnerPathname,
        pathQueryParam: "owner_id",
        setQueryParam: true,
    };
    const viewOpportunityButtonCommonParams = {
        dataKey: "opportunity_id",
        includeAllQueryParams: true,
        pathQueryParam: "opportunity_id",
    };

    const tableOptions = {
        [FEED_TYPES.TEAM_ACTIVITY]: {
            columns: [
                "userName",
                "userEmail",
                "userCreationDate",
                "userLastLoginDate",
                "userIsActive",
            ],
            recordTitle: "User",
            recordTitlePlural: "Users",
        },
        [FEED_TYPES.ALL_FEEDBACK]: {
            clickParams: {
                viewButton: singleFeedbackPathParams,
            },
            columns: [
                "feedback_name",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't provided any feedback yet. To take advantage of this feature, simply provide feedback or log progress on any opportunity.",
                "Have questions? contact"
            ),
            recordTitle: "Feedback",
            recordTitlePlural: "Feedback",
        },
        [FEED_TYPES.ALL_ASSIGNEES]: {
            clickParams: {
                viewButton: singleAssigneePathParams,
            },
            columns: [
                "salesManager",
                "assignee",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't assigned any insights yet. To take advantage of this feature, simply assign opportunities to your team",
                "or contact"
            ),
            recordTitle: "Assignee",
        },
        [FEED_TYPES.ALL_INDICATORS]: {
            clickParams: {
                viewButton: singleIndicatorPathParams,
            },
            columns: [
                "market",
                "allIndicatorsIndicator",
                "rank",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            recordTitle: "Indicator",
        },
        [FEED_TYPES.ALL_INSIGHTS]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IntelligenceReportsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "reportDate",
                "status",
                "state",
                "opportunityOwnerString",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
        },
        [FEED_TYPES.ALL_OWNERS]: {
            clickParams: {
                viewButton: singleOwnerPathParams,
            },
            columns: [
                "ownerName",
                "rank",
                "state",
                "ownerPopulation",
                "fiscalStart",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            recordTitle: "Owner",
        },
        [FEED_TYPES.ALL_TEAM_INSIGHTS]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
        },
        [FEED_TYPES.INDICATOR_FEED]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IndicatorFeedsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "displayDate",
                "status",
                "state",
                "opportunityOwnerString",
                "indicators",
                "triggerExtract",
            ],
            expandableRow: true,
            insightTagTitle: "Indicator Feed",
            recordTitle: "Insight",
        },
        [FEED_TYPES.MY_INSIGHTS]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: MyInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you have not yet been assigned to any insights. To take advantage of this feature, please contact your sales manager",
                "or"
            ),
            expandableRow: true,
            recordTitle: "Insight",
        },
        [FEED_TYPES.PRIORITY_INSIGHTS]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: PriorityInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
        },
        [FEED_TYPES.SINGLE_ASSIGNEE]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AssignedInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't assigned any insights to this user yet. To take advantage of this feature, simply assign opportunities to this user",
                "or contact"
            ),
            expandableRow: true,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("user_id")),
        },
        [FEED_TYPES.SINGLE_FEEDBACK]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllFeedbackSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
            secondaryId: new URLSearchParams(window.location.search).get("feedback"),
        },
        [FEED_TYPES.SINGLE_INDICATOR]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllIndicatorsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("indicator_id")),
        },
        [FEED_TYPES.SINGLE_OWNER]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllOwnersSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("owner_id")),
        },
        [FEED_TYPES.SINGLE_REPORT]: {
            bulkActions: true,
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IntelligenceReportsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "status",
                "state",
                "opportunityOwnerString",
                "population",
                "indicators",
                "summary",
            ],
            expandableRow: true,
            insightTagTitle: "Report",
            recordTitle: "Insight",
        },
    };

    const relevantId = [FEED_TYPES.INDICATOR_FEED, FEED_TYPES.SINGLE_REPORT].includes(
        feedType
    )
        ? contentId
        : teamId;

    const isStaff = getWhoamiQuery.isSuccess
        ? getWhoamiQuery.data.user.is_staff
        : false;

    const tableFiltersQueryEnabled = getWhoamiQuery.isSuccess
        ? feedType === FEED_TYPES.ALL_INSIGHTS && isStaff
            ? filtersEnabled
            : true
        : false;

    const tableDataQuery = useGetTableData(feedType);
    const tableFiltersQuery = useGetTableFilters(
        feedType,
        relevantId,
        tableOptions[feedType]?.secondaryId,
        tableFiltersQueryEnabled
    );

    return {
        dataQuery: tableDataQuery,
        filtersQuery: tableFiltersQuery,
        relevantId,
        tableOptions: tableOptions[feedType],
    };
};
