import { additionalColors, brandColors } from "constants/colors";
import { FEEDBACK_REACTIONS } from "constants/namingSchemeTranslations";
import {
    PaginatedTableFilteredInfoType,
    PaginatedTableSortedInfoType,
} from "types/additionalTypes";

import buttonColumn from "./columns/buttonColumn";
import dateColumn from "./columns/dateColumn";
import numberColumn from "./columns/numberColumn";
import tagsColumn from "./columns/tagsColumn";
import textColumn from "./columns/textColumn";

interface getColumnsTypes {
    filteredInfo: PaginatedTableFilteredInfoType;
    filters: any;
    history: any;
    isLoadingFilters: boolean;
    searchTextValue?: string;
    sortedInfo: PaginatedTableSortedInfoType;
    styles: any;
    tableOptions: any;
}

export const getColumns = ({
    filteredInfo,
    filters,
    history,
    isLoadingFilters,
    searchTextValue,
    sortedInfo,
    styles,
    tableOptions,
}: getColumnsTypes) => {
    const columnFunctions = {
        allIndicatorsIndicator: tagsColumn,
        assignee: textColumn,
        displayDate: dateColumn,
        feedback_name: textColumn,
        fiscalStart: textColumn,
        indicators: tagsColumn,
        insightCount: numberColumn,
        market: textColumn,
        opportunityOwner: textColumn,
        opportunityOwnerString: textColumn,
        ownerName: textColumn,
        ownerPopulation: numberColumn,
        population: numberColumn,
        priorityInsight: tagsColumn,
        rank: tagsColumn,
        reportDate: dateColumn,
        salesManager: textColumn,
        state: textColumn,
        status: tagsColumn,
        summary: textColumn,
        totalOpportunityValue: numberColumn,
        totalOpportunityValueNoSorting: numberColumn,
        triggerExtract: textColumn,
        viewButton: buttonColumn,
        userName: textColumn,
        userEmail: textColumn,
        userCreationDate: dateColumn,
        userLastLoginDate: dateColumn,
        userIsActive: tagsColumn,
    };

    const commonColumnParams = {
        filteredInfo,
        history,
        isLoading: isLoadingFilters,
        searchTextValue,
        sortedInfo,
        styles,
    };

    const specificColumnParams = {
        userName: {
            filters: filters?.name_filter,
            key: "name",
            title: "Name",
        },
        userEmail: {
            filters: filters?.email_filter,
            key: "email",
            title: "Email",
        },
        userCreationDate: {
            allowFiltering: false,
            key: "user_creation_date",
            title: "Profile Creation Date",
        },
        userLastLoginDate: {
            allowFiltering: false,
            key: "last_login_date",
            title: "Last Login Date",
        },
        userIsActive: {
            colors: {
                false: brandColors.red,
                true: additionalColors.green500,
            },
            displayMap: {
                false: "Inactive",
                true: "Active",
            },
            filters: filters?.is_active_filter,
            filtersMap: [
                { text: "Inactive", value: String(false) },
                { text: "Active", value: String(true) },
            ],
            key: "is_active",
            title: "Status",
        },
        allIndicatorsIndicator: {
            allowSorting: false,
            filters: filters?.indicator_filter,
            key: "indicator",
            title: "Indicators",
        },
        assignee: {
            filters: filters?.assignee_filter,
            key: "assignee",
            title: "Assignee",
        },
        displayDate: {
            filters: filters?.date_filter,
            key: "display_date",
            title: "Date",
        },
        feedback_name: {
            displayTranslations: FEEDBACK_REACTIONS,
            filters: filters?.feedback_name_filter,
            key: "feedback_name",
            title: "Feedback",
        },
        fiscalStart: {
            filters: filters?.fiscal_start_filter,
            key: "fiscal_start",
            title: "Fiscal Start",
        },
        indicators: {
            allowSorting: false,
            clickParams: tableOptions.clickParams?.indicator
                ? {
                      excludeQueryParams:
                          tableOptions.clickParams.indicator.excludeQueryParams,
                      path: tableOptions.clickParams.indicator.path,
                      queryParam: tableOptions.clickParams.indicator.pathQueryParam,
                      setQueryParam: tableOptions.clickParams.indicator.setQueryParam,
                  }
                : null,
            filters: filters?.indicator_filter,
            key: "indicator_groups",
            title: "Indicators",
        },
        insightCount: {
            filters: filters?.total_insights_filter,
            key: "total_insights",
            title: "Count of Insights",
        },
        market: {
            allowSorting: false,
            allowFiltering: false,
            filters: filters?.market_filter,
            key: "market",
            title: "Market",
        },
        opportunityOwner: {
            clickParams: tableOptions.clickParams?.owner
                ? {
                      dataKey: tableOptions.clickParams.owner.dataKey,
                      excludeQueryParams:
                          tableOptions.clickParams.owner.excludeQueryParams,
                      path: tableOptions.clickParams.owner.path,
                      queryParam: tableOptions.clickParams.owner.pathQueryParam,
                      setQueryParam: tableOptions.clickParams.owner.setQueryParam,
                  }
                : null,
            filters: filters?.opportunity_owner_filter,
            key: "opportunity_owner",
            title: "Opportunity Owner",
            valueKey: "opportunity_owner.legal_name",
        },
        opportunityOwnerString: {
            filters: filters?.opportunity_owner_filter,
            key: "opportunity_owner",
            title: "Opportunity Owner",
            valueKey: "opportunity_owner.legal_name",
        },
        ownerName: {
            filters: filters?.owner_filter,
            key: "owner",
            title: "Owner",
        },
        ownerPopulation: {
            filters: filters?.population_filter,
            key: "population",
            title: "Population",
        },
        population: {
            filters: filters?.population_filter,
            key: "total_population",
            title: "Population",
        },
        priorityInsight: {
            allowSorting: false,
            colors: {
                feeds: additionalColors.grey500,
                priority: brandColors.darkBlue,
            },
            displayMap: {
                false: "Feeds",
                true: "Priority",
            },
            filters: filters?.priority_insight_filter,
            filtersMap: [
                { text: "Feeds", value: String(false) },
                { text: "Priority", value: String(true) },
            ],
            key: "priority_insight",
            title: "Rank",
        },
        rank: {
            allowSorting: false,
            colors: {
                feeds: additionalColors.grey500,
                priority: brandColors.darkBlue,
            },
            displayMap: {
                feeds: "Feeds",
                priority: "Priority",
            },
            filters: filters?.rank_filter,
            key: "rank",
            title: "Rank",
        },
        reportDate: {
            filters: filters?.date_filter,
            key: "report_date",
            title: "Report Date",
        },
        salesManager: {
            filters: filters?.sales_manager_filter,
            key: "sales_manager",
            title: "Sales Manager",
        },
        state: {
            filters: filters?.state_filter,
            key: "state_code",
            title: "State",
            valueKey: "opportunity_owner.state_code",
        },
        status: {
            colors: {
                false: brandColors.darkBlue,
                true: brandColors.lightBlue,
            },
            displayMap: {
                false: "New",
                true: "Updated",
            },
            filters: filters?.status_filter,
            filtersMap: [
                { text: "New", value: String(false) },
                { text: "Updated", value: String(true) },
            ],
            key: "opportunity_updated",
            title: "Status",
        },
        summary: {
            allowFiltering: false,
            allowSorting: false,
            key: "summary",
            title: "Summary",
        },
        // No longer being used, see DEV-3389 for more information
        // totalOpportunityValue: {
        //     filters: filters?.total_opportunity_value_filter,
        //     format: "currency",
        //     key: "total_opportunity_value",
        //     title: "Total Opportunity Value",
        // },
        totalOpportunityValueNoSorting: {
            allowFiltering: false,
            allowSorting: false,
            format: "currency",
            key: "total_opportunity_value",
            title: "Total Opportunity Value",
        },
        triggerExtract: {
            allowFiltering: false,
            key: "trigger_extract",
            title: "Trigger Extract",
        },
        viewButton: {
            buttonPath: tableOptions.clickParams?.viewButton?.path,
            buttonPathQueryParam: tableOptions.clickParams?.viewButton?.pathQueryParam,
            excludeQueryParams:
                tableOptions.clickParams?.viewButton?.excludeQueryParams,
            key: tableOptions.clickParams?.viewButton?.dataKey,
            setQueryParam: tableOptions.clickParams?.viewButton?.setQueryParam,
        },
    };

    const columns = tableOptions.columns
        .map((columnName) => {
            if (columnFunctions[columnName]) {
                return columnFunctions[columnName]({
                    ...commonColumnParams,
                    ...(specificColumnParams[columnName]
                        ? specificColumnParams[columnName]
                        : {}),
                });
            }
            return null;
        })
        .filter(Boolean);

    return columns;
};
